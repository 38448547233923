.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 935px;
  @media screen and (max-width: 1370px) {
    max-width: 800px;
  }
}
