::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
  background: #a6a6a6;
  border-radius: 4px;
}
