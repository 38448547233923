.tooltip {
  z-index: 50;
  align-items: baseline;
  display: flex;
}

.YearMonthPicker {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.YearMonthPickerBody {
  border-bottom: 1px solid #cfcfcf !important;

  &:global(._isError) {
    border-bottom: 1px solid #d32f2f !important;
  }
}


.link {
  position: absolute;
  right: 4px;
  top: 24px;
  cursor: pointer;
  z-index: 10;
}

.name {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
