:global(.icon) {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

:global(.dropDownRow) {
  position: relative;

  &:global(._isOpen) {
    :global(.dropDownMenu) {
      opacity: 1;
      visibility: visible;
    }
  }
}

:global(.dropDownIcon) {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.dropDownMenu) {
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 14px;
  min-width: 100px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 11%, rgba(255,255,255,1) 100%);
  opacity: 0;
  visibility: hidden;

  & > * {
    opacity: 0;
    margin-left: 24px;
    animation: show-item 0.2s cubic-bezier(0.4, 1.2, 0.6, 1.01) forwards;
    &:first-child {
      margin-left: 0;
    }
    @for $i from 1 to 100 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
  svg {
    cursor: pointer;
    vertical-align: middle;
  }
}

:global(.menuToggle) {
  border: 1px solid #A6A6A6;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #A6A6A6;
  }
}

@keyframes show-item {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
