.search {
  &:global(.isOpen) {
    .icon {
      display: none;
    }
    .input {
      display: block;
    }
    .close {
      display: flex;
      align-items: center;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
  .input {
    display: none;
    input {
      padding: 10px 40px 10px 10px;
      font-size: 12px;
      width: 440px;
      background-color: #fff;
    }
    fieldset {
      border-color: #4b61bf;
    }
  }
  .close {
    display: none;
    cursor: pointer;
    color: #4b61bf;
    height: 100%;
  }
}
