.emojiContainer{
  em-emoji-picker{
    width:auto;
    border: none !important;
    border-radius: inherit;
    border-width: 0;
  }

  :global(.flex.flex-column){
    width:320px
  }
}
