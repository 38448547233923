.info {
  background: rgba(2, 136, 209, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 14px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:global(._isError) {
    background: rgba(211, 47, 47, 0.1);
  }

  svg {
    vertical-align: middle;
    margin-right: 22px;
  }
  span {
    vertical-align: middle;
    font-size: 14px;
    line-height: 17px;
    color: #171717;
  }

  .close {
    margin-right: 0;
    cursor: pointer;
  }
}
