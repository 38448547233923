.status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 2px solid #cfcfcf;
  margin: 0 auto;

  &:global(._isSuccess) {
    border: none;
    background: #2e7d32;
  }
  &:global(._isWarring) {
    border: none;
    background: #ed6c02;
  }
  &:global(._isError) {
    border: none;
    background: #d32f2f;
  }
}

.name {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  display: block;
}

.tabToTop {
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}

.sort {
  display: inline;
  font-size: 18px;
  margin-right: 4px;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
  }

  svg {
   path {
    &:first-child {
      fill: #6A81E3;
    }
   }
  }
}

.default {
  display: inline;
  font-size: 18px;
  margin-right: 4px;
  margin-left: 4px;
}
