.value {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-left: 4px;
}

.menu {
  padding: 22px 30px 30px 30px;
  min-width: 390px;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
  }
  &_body {
    padding-top: 20px;
    & > div {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &_actions {
    @extend .menu_header;
    margin-top: 30px;
  }
}
