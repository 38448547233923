.erir {
    padding: 18px 22px;
    background: rgba(207, 207, 207, 0.2);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    &_info {
      display: grid;
      grid-template-columns: 8px auto;
      align-items: center;
      gap: 0 12px;
    }
    .status {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      border: 1px solid #A6A6A6;
  
      &:global(._isSuccess) {
        border: none;
        background: #2e7d32;
      }
      &:global(._isWarring) {
        border: none;
        background: #ed6c02;
      }
      &:global(._isError) {
        border: none;
        background: #d32f2f;
      }
    }
  }
  