.loader {
  width: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;

  &_circle {
    position: relative;
    width: 8px;
    height: 8px;
    background: #4b61bf;
    box-shadow: -14px 0px 0px #647dec;
    border-radius: 50%;
    animation: circle_classic 1s ease-in-out infinite alternate;
  }
}

@keyframes circle_classic {
  0% {
    opacity: 0.1;
    transform: rotate(0deg) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg) scale(1.05);
  }
}
