.container{
  width: 360px;
  height: inherit;
  display: flex;
  flex-direction: column;

  :global([data-viewport-type]){
    position:inherit !important;
    //height: auto !important;
  }
  :global(.height-preserving-container){
    padding: 5px;
    &:empty{
      min-height: calc(var(--child-height));
      box-sizing: border-box;
    }
  }
  :global(.virtuoso-item-list){
    & + div{
      position: sticky;
      top: 0;
    }
  }
}
