.YearMonthPicker {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.YearMonthPickerBody {
  border-bottom: 1px solid #cfcfcf !important;

  &:global(._isError) {
    border-bottom: 1px solid #D32F2F !important;
  }
}