$pickerSelectedColor: #216ba5;
$pickerTextColor:white;
@mixin picker{
 background: $pickerSelectedColor !important;
 color: $pickerTextColor !important;
}
.container{
  :global(.react-datepicker__day.react-datepicker__day--keyboard-selected ){
    background: none ;
    color: black ;
  }

  .date-picker-field__range {
    @include  picker;
  }

  .date-picker-field__active--start {
    @include  picker;
  }

 .date-picker-field__active--end {
   @include  picker;
  }

  .date-picker-field__active {
    @include  picker;
  }


  :global(.react-datepicker){
    width: 100%;
  }
  :global(.react-datepicker__month-container){
    width: 100%;
  }

}
