html,
body,
#root {
  height: 100%;
  scrollbar-gutter: stable;
}

a {
  text-decoration: none;
  color: inherit;
}

._Markdown{

  ol,ul {
    margin: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    white-space: initial;
    padding: 0 0 0 40px;
  }

  li{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  p{
    margin-bottom: 0;
    ~ ol,ul{
      margin-top:-15px
    }
  }

  pre {
    overflow: auto;
  }

  a{
    color: rgb(25, 118, 210)
  }
}

