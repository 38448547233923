.status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 2px solid #cfcfcf;

  &:global(._isSuccess) {
    border: none;
    background: #2e7d32;
  }
  &:global(._isWarring) {
    border: none;
    background: #ed6c02;
  }
  &:global(._isError) {
    border: none;
    background: #d32f2f;
  }
}
.name {
  word-wrap: break-word;
}
