.DividerShow {
  position: fixed;
  bottom: 20px;
  left: 3px;
  z-index: 2000;
  background: #f2f5f6;
  border: 1px solid #A6A6A6;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: #A6A6A6;
    transition: 0.3s;
  }

  &:hover {
    svg {
      color: #121212;
    }
  }
}

.DividerHide {
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 1201;
  height: 64px;
  background: #fff;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: auto;
  overflow: hidden;

  svg {
    transition: 0.3s;
  }

  &:hover {
    svg {
      color: #121212;
    }
  }
}

.ListItemButton {
  display: flex;
  align-items: center;

  svg {
    margin-right: 34px;
  }
}
