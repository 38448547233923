.status {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 2px solid #cfcfcf;
  margin: 0 auto;

  &:global(._isSuccess) {
    border: none;
    background: #2e7d32;
  }
  &:global(._isWarring) {
    border: none;
    background: #ed6c02;
  }
  &:global(._isError) {
    border: none;
    background: #d32f2f;
  }
}

.aliases {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.name,
.inn {
  @extend .aliases;
  display: block;
}

.tabToTop {
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}

.api {
  max-width: 50px;
  padding: 0 12px;
  border-radius: 64px;
  background: #cfcfcf;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  &:global(._isSuccess) {
    background: #2e7d32;
    color: #ffffff;
  }
}
