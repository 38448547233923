.aliases {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.name,
.inn {
  @extend .aliases;
  display: block;
}

.tabToTop {
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
}
